<div *ngIf="show">
  <div class="index-nav">
    <div
      *ngIf="!isExpanded"
      class="index-icon"
      (click)="playButton(isExpanded)"
      onClick="document.getElementById('mymarquee').stop();"
    >
      <mat-icon>pause</mat-icon>
    </div>
    <div
      *ngIf="isExpanded"
      class="index-icon"
      (click)="playButton(isExpanded)"
      onClick="document.getElementById('mymarquee').start();"
    >
      <mat-icon>play_arrow</mat-icon>
    </div>
    <marquee
      *ngIf="isMobile"
      behavior="scroll"
      direction="left"
      onmouseover="this.stop();"
      onmouseout="this.start();"
      id="mymarquee"
      scrolldelay="190"
    >
      <app-capital-markets-section></app-capital-markets-section>
    </marquee>

    <marquee
      *ngIf="!isMobile"
      behavior="scroll"
      direction="left"
      onmouseover="this.stop();"
      onmouseout="this.start();"
      id="mymarquee"
      scrolldelay="90"
    >
      <app-capital-markets-section></app-capital-markets-section>
    </marquee>
  </div>
  <div class="border-section container-sections">
    <div style="text-align: center;">
      <div>
        <label
          (click)="goHome()"
          *ngIf="currentCode == 'br'"
          class="font-title current"
          for=""
          style="margin-right: 10px;"
          >REsource BR</label
        >
        <label
          (click)="goHome()"
          *ngIf="currentCode == 'mx'"
          class="font-title current"
          for=""
          style="margin-right: 10px;"
          >REsource MX</label
        >
        <label
          (click)="goHome()"
          *ngIf="currentCode == 'latam'"
          class="font-title current"
          for=""
          style="margin-right: 10px;"
          >REsource LATAM</label
        >
        <!-- <label (click)="goHome()" for="" class="font-title re">{{
          "news.re" | translate
        }}</label>
        <label (click)="goHome()" for="" class="font-title source">{{
          "news.source" | translate
        }}</label> -->
      </div>
    </div>
    <section class="news-container">
      <div class="first-section" #mainDiv>
        <hr class="hr-sections" />
        <h1 *ngIf="!isMobile" style="font-weight: 700;">
          {{ news?.headline | langSelector }}
        </h1>
        <h3 *ngIf="isMobile" style="font-weight: 700;">
          {{ news?.headline | langSelector }}
        </h3>
        <div
          class="mb-4 mt-3 ml-2"
          *ngIf="
            currentCode == 'latam' ||
            ($isLoggedIn | async) ||
            !news.subscriberOnly
          "
        >
          <span
            style="font-size: 20px;"
            [innerHTML]="news?.summary | langSelector"
          ></span>
        </div>
        <div *ngIf="news.headerImage">
          <img
            class="w-100 aspect-4-3"
            [src]="headerImageNews(news.headerImage.fullPath, true)"
            [alt]="headerImgCaption"
            [title]="headerImgCaption"
          />
          <figcaption
            class="ml-2 mb-3"
            style="font-size: 14px;text-align: center;"
          >
            {{ news.headerImageCaption | langSelector }}
          </figcaption>
        </div>
        <div style="display: flex;justify-content: space-between;">
          <div *ngIf="news.externalAuthors?.length > 0">
            <strong *ngIf="news.externalAuthors && news.externalAuthors[0]"
              >{{ "news.by" | translate }}
            </strong>
            <span
              style="color: #007bff;font-weight: 600;"
              *ngIf="news.externalAuthors && news.externalAuthors[0]"
              [innerHTML]="news.externalAuthors[0].name | langSelector"
            ></span>
            <span
              *ngIf="news.externalAuthors && news.externalAuthors.length > 1"
              style="color: #007bff;font-weight: 600;"
            >
              &
            </span>
            <span
              style="color: #007bff;font-weight: 600;"
              *ngIf="news.externalAuthors && news.externalAuthors[1]"
              [innerHTML]="news.externalAuthors[1].name | langSelector"
            ></span>
          </div>
          <div
            *ngIf="
              news.externalAuthors?.length == 0 &&
              news.internalAuthors?.length > 0
            "
          >
            <strong *ngIf="news.internalAuthors && news.internalAuthors[0]"
              >{{ "news.by" | translate }}
            </strong>
            <span *ngIf="news.internalAuthors && news.internalAuthors[0]">{{
              news.internalAuthors[0]
            }}</span>
            <span *ngIf="news.internalAuthors && news.internalAuthors[1]">
              & {{ news.internalAuthors[1] }}</span
            >
          </div>
          <strong *ngIf="getLanguage() === 'en'">
            {{ formatDate(news.publishDate, "MM/dd/yyyy") }}
          </strong>
          <strong *ngIf="getLanguage() === 'es'">
            {{ formatDate(news.publishDate, "dd/MM/yyyy") }}
          </strong>
          <strong *ngIf="getLanguage() === 'pt'">
            {{ formatDate(news.publishDate, "dd/MM/yyyy") }}
          </strong>
        </div>
        <div
          *ngIf="isMobile"
          class="social-media-container"
          style="margin-top: 1rem; margin-bottom: 1rem;justify-content: center;"
        >
          <div class="social-media-buttons">
            <img
              *ngFor="let socialLink of socialMediaLinks"
              class="image-social"
              [src]="socialLink.img"
              (click)="shareLink(socialLink)"
              [alt]="socialLink.alt"
              [title]="socialLink.title"
            />
          </div>
        </div>
        <div class="mt-4 ml-2 primary-content-kk">
          <span
            *ngIf="
              currentCode != 'latam' &&
              !($isLoggedIn | async) &&
              news.subscriberOnly
            "
            style="font-size: 18px;"
            [innerHTML]="translatedPrimaryContent | truncateHtml: 150"
          ></span>
          <span
            *ngIf="
              currentCode == 'latam' ||
              ($isLoggedIn | async) ||
              !news.subscriberOnly
            "
            style="font-size: 18px;"
            [innerHTML]="translatedPrimaryContent"
          ></span>
        </div>
        <div
          [ngClass]="{
            'blur-news':
              currentCode != 'latam' &&
              !($isLoggedIn | async) &&
              news.subscriberOnly
          }"
        >
          <div
            id="sectionImgs"
            class="home-card-carousel"
            *ngFor="let news of news.sectionsInfo"
          >
            <h2 class="mt-2 title-section">
              {{ news.sectionTitle | langSelector }}
            </h2>
            <div *ngIf="news.videoURL">
              <iframe
                class="video-frame"
                [src]="news.videoURL"
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              ></iframe>
              <figcaption
                class="ml-2"
                style="font-size: 14px;text-align: center;"
              >
                {{ news.caption | langSelector }}
              </figcaption>
            </div>
            <div
              id="newSectionImgs"
              *ngIf="news.sectionImgsRES && !news.videoURL"
              class="mt-3"
            >
              <div
                class="slick-carousel-segment"
                [ngStyle]="{ 'flex-direction': handsetMode ? 'column' : 'row' }"
              >
                <div class="slick-carousel-contain">
                  <ngx-slick-carousel
                    [prevArrow]="false"
                    [nextArrow]="false"
                    style="width:97%;overflow: hidden;"
                    class="carousel carousel-height"
                    #slickCarousel
                    (init)="slickInit($event)"
                    [config]="slideConfig"
                  >
                    <div
                      ngxSlickItem
                      *ngFor="let image of news.sectionImgsRES"
                      class="slide"
                      style="padding-top: 20px;"
                    >
                      <app-detail-carousel
                        [news]="news"
                        [image]="image"
                      ></app-detail-carousel>
                    </div>
                  </ngx-slick-carousel>
                  <button
                    class="leftArrow"
                    (click)="loadPage('back', slickCarousel)"
                    style="background:darkgray;"
                    *ngIf="sectionImages?.length > 1"
                  >
                    <mat-icon>keyboard_arrow_left</mat-icon>
                  </button>

                  <button
                    (click)="loadPage('next', slickCarousel)"
                    style="background:darkgray;"
                    class="rightArrow"
                    *ngIf="sectionImages?.length > 1"
                  >
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="ml-2 mt-4">
              <span
                style="font-size: 18px;"
                [innerHTML]="news?.description | langSelector"
              ></span>
            </div>
          </div>

          <section style="display: none;">
            <ngx-light-carousel [options]="defaultSlide"> </ngx-light-carousel>
          </section>

          <div
            class="mt-5"
            *ngIf="news.externalAuthors && news.externalAuthors.length > 0"
          >
            <h2 *ngIf="news.externalAuthors?.length > 1" class="title-card">
              {{ "news.aboutAuthors" | translate }}
            </h2>
            <h2 *ngIf="news.externalAuthors?.length == 1" class="title-card">
              {{ "news.aboutAuthor" | translate }}
            </h2>
            <div class="authors-card mt-5">
              <div
                *ngFor="let externaAuthor of news.externalAuthors.slice(0, 2)"
                class="authors-card "
              >
                <div style="text-align: center;">
                  <img
                    class="img-authors"
                    [src]="headerImageNews(externaAuthor.imageDTO.fullPath)"
                    alt="SiiLA"
                    title="SiiLA"
                  />
                </div>
                <div
                  style="text-align: justify;
            font-size: 20px;
            margin-top: 34px;"
                >
                  <div
                    style="font-weight: 600;"
                    class="author-link "
                    [innerHTML]="externaAuthor.name | langSelector"
                  ></div>
                  <div
                    class="author-link"
                    [innerHTML]="externaAuthor.title | langSelector"
                  ></div>
                  <div
                    class="author-link"
                    [innerHTML]="externaAuthor.companyName | langSelector"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="border-subscriber card-subscriber"
          *ngIf="
            currentCode != 'latam' &&
            !($isLoggedIn | async) &&
            news.subscriberOnly
          "
        >
          <div class="text-center">
            <span class="title-subscriber mt-2">{{
              "news.titleSubscriber" | translate
            }}</span>
            <br />
            <span class="title-subscriber mt-2">{{
              "news.subscriber" | translate
            }}</span
            ><span (click)="login()" class="subscriber-text">{{
              "news.sign" | translate
            }}</span>
          </div>
          <div class="mt-2">
            <span class="interested-text">{{
              "news.subscriberText" | translate
            }}</span>
            <a class="subscriber-text" (click)="openContactDialog()">{{
              "news.contact" | translate
            }}</a>
            <span class="interested-text">{{ "news.about" | translate }}</span
            ><a class="subscriber-text" (click)="openProduct()">{{
              "news.sillaMarket" | translate
            }}</a>
          </div>
          <div class="text-center mt-2">
            <img
              style="width: 34%;"
              class="brand-style"
              title="SiiLA – Dark Blue and Orange logo"
              alt="SiiLA – Dark Blue and Orange logo"
              src="assets/img/SiiLA_SVG.svg"
            />
          </div>
        </div>
        <div class="mt-5">
          <div *ngIf="news.contentTags" class="wrapper">
            <div>
              <span
                class="form-control tags"
                (click)="goHome(true)"
                style="text-transform: uppercase;"
                >Latam</span
              >
            </div>
            <div *ngIf="currentCode == 'br'">
              <span class="form-control tags" (click)="goHome()">
                {{ "navLinks.siilaBrasil" | translate }}
              </span>
            </div>
            <div *ngIf="currentCode == 'mx'">
              <span class="form-control tags" (click)="goHome()">
                {{ "navLinks.siilaMexico" | translate }}
              </span>
            </div>
            <div *ngFor="let stateTag of news.contentTags.stateTags">
              <span class="form-control tags" (click)="goNewsTags(stateTag, 2)">
                {{ stateTag.name | langSelector }}
              </span>
            </div>
            <div
              *ngFor="let propertyTypeTag of news.contentTags.propertyTypeTags"
            >
              <span
                class="form-control tags"
                (click)="goNewsTags(propertyTypeTag, 1)"
              >
                {{ propertyTypeTag.name | langSelector }}
              </span>
            </div>
            <div *ngFor="let productTag of news.contentTags.productTags">
              <span
                class="form-control tags"
                (click)="goNewsTags(productTag, 1)"
              >
                {{ productTag.name | langSelector }}
              </span>
            </div>
            <div
              *ngIf="
                news.topic &&
                validateOtherTopics(news.topic.name | langSelector)
              "
            >
              <span
                (click)="goNewsTags(news.topic, 1, 'topic')"
                class="form-control tags"
              >
                {{ news.topic.name | langSelector }}
              </span>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <div class="mt-5">
            <h2 class="title-card mb-3">{{ "news.aboutSiila" | translate }}</h2>
            <span>{{ "news.aboutText" | translate }}</span>
          </div>
        </div>
      </div>
      <div class="second-section">
        <hr class="hr-sections" />
        <div
          *ngIf="!isMobile"
          class="social-media-container"
          style="margin-top: 1rem; margin-bottom: 1rem;justify-content: center;"
        >
          <div class="social-media-buttons">
            <img
              *ngFor="let socialLink of socialMediaLinks"
              class="image-social"
              [src]="socialLink.img"
              (click)="shareLink(socialLink)"
              [alt]="socialLink.alt"
              [title]="socialLink.title"
            />
          </div>
        </div>
        <div
          style="background: #00334D;color: white;border-radius: 6px 6px 0px 0px;text-align: center;"
        >
          <label class="mt-2 ml-3" for="label mailing">
            {{ "news.mostRead" | translate }}</label
          >
        </div>
        <div
          *ngFor="let mostNews of mostRead"
          class="p-2 most-read"
          (click)="openNews(mostNews.id, mostNews)"
          style="cursor: pointer;border-bottom: 1px solid gainsboro;"
        >
          <h5 style="font-weight: 700;">
            {{ mostNews.headline | langSelector }}
          </h5>
          <span *ngIf="getLanguage() === 'en'">
            {{ formatDate(mostNews.publishDate, "MM/dd/yyyy") }}
          </span>
          <span *ngIf="getLanguage() === 'es'">
            {{ formatDate(mostNews.publishDate, "dd/MM/yyyy") }}
          </span>
          <span *ngIf="getLanguage() === 'pt'">
            {{ formatDate(mostNews.publishDate, "dd/MM/yyyy") }}
          </span>
        </div>
        <div *ngIf="false">
          <img
            [alt]="siilaAcademyBannerSEO.title"
            [title]="siilaAcademyBannerSEO.title"
            class="w-100"
            [src]="siilaAcademyBannerSEO.src"
            (click)="openAcademy()"
            style="cursor: pointer;"
          />
        </div>
        <div class="mt-3">
          <img
            [alt]="siilaBrandSEO.title"
            [title]="siilaBrandSEO.title"
            class="w-100"
            [src]="siilaBrandSEO.src"
            (click)="openMultifamilyBanner()"
            style="cursor: pointer;"
          />
        </div>
        <div class="mt-3 details-news-spotseo">
          <img
            [alt]="spotTransformingSEO.alt"
            [title]="spotTransformingSEO.title"
            class="w-100"
            [src]="spotTransformingSEO.src"
            (click)="openSpot()"
            style="cursor: pointer;"
          />
        </div>

        <div class="mt-3">
          <h4 class="title-card">{{ topics[0]?.name | langSelector }}</h4>
          <hr class="hr-sections" />
          <div
            *ngFor="let topic of topics[0]?.newsList.slice(0, 2)"
            class="mat-elevation-z8"
            (click)="openNews(topic.id, topic)"
            style="border-radius: 8px;cursor: pointer;"
          >
            <img
              class="w-100 img-topic aspect-4-3"
              [alt]="getAltTxt(topic.headerImageCaption)"
              [title]="getAltTxt(topic.headerImageCaption)"
              [src]="headerImageNews(topic.headerImage.fullPath)"
            />
            <div class="m-2 mt-3 pb-2">
              <span style="font-weight: 700;">{{
                topic.headline | langSelector
              }}</span>
              <!-- <br />
              <span class="date">{{ topic.createdDate | date: "longDate" }}</span> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="news-container" style="display: block;">
      <div class="mt-5" style="text-align: center;">
        <h4 class="title-card mb-4">{{ topics[1]?.name | langSelector }}</h4>
        <div class="topics" style="justify-content: space-between;">
          <div
            (click)="openNews(topic.id, topic)"
            style="cursor: pointer;"
            *ngFor="let topic of topics[1]?.newsList.slice(0, 2)"
            class="mat-elevation-z8 topics-card"
          >
            <img
              style="max-height: 418px;"
              class="w-100 img-topic aspect-4-3"
              [alt]="getAltTxt(topic.headerImageCaption)"
              [title]="getAltTxt(topic.headerImageCaption)"
              [src]="
                topic.headerImage?.fullPath
                  ? headerImageNews(topic.headerImage?.fullPath)
                  : ''
              "
            />
            <div class="m-2 mt-3">
              <span style="font-weight: 700;">{{
                topic.headline | langSelector
              }}</span>
              <!--  <br />
              <span class="date-read" *ngIf="getLanguage() === 'en'">
                {{ formatDate(topic.publishDate, "MM/dd/yyyy") }}
              </span>
              <span class="date-read" *ngIf="getLanguage() === 'es'">
                {{ formatDate(topic.publishDate, "dd/MM/yyyy") }}
              </span>
              <span class="date-read" *ngIf="getLanguage() === 'pt'">
                {{ formatDate(topic.publishDate, "dd/MM/yyyy") }}
              </span> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="news-container" style="display: block;">
    <app-industry resource="true"></app-industry>
    <app-exclusive-access></app-exclusive-access>
  </div>
  <app-btn-up></app-btn-up>
</div>
